import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/1.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/10.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/11.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/12.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/13.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/2.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/3.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/4.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/5.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/6.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/7.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/8.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/carousel/9.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/images/bg_wave1.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/images/bg_wave2.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/components/Carousel/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/components/CookieConsent/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/components/PortalButton/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/components/PortalText/index.tsx")